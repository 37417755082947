import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Row, Spin } from 'antd';

import './App.css';
import { DBlockStateProvider } from "./store/DBlockStateProvider";

const PublicRoutes = lazy(() => import('./router/PublicRoutes'));
const PrivateRoutes = lazy(() => import('./router/PrivateRoutes'));
const AppLayout = lazy(() => import('./components/Layout/AppLayout'));

const ProjectSettingPage = lazy(() => import('./pages/ProjectSettingPage'));
const UserSettingPage = lazy(() => import('./pages/UserSettingPage'));

const DMPage = lazy(() => import('./pages/DMPage'));

const QueryPage = lazy(() => import('./pages/QueryPage'));

const LoginPage = lazy(() => import('./pages/LoginPage'));
const ProjectPage = lazy(() => import('./pages/ProjectPage'));
const OverviewPage = lazy(() => import('./pages/OverviewPage'));
const SubjectPage = lazy(() => import('./pages/SubjectPage'));
const VisitPage = lazy(() => import('./pages/VisitPage'));
const ExpiredPage = lazy(() => import('./pages/ExpiredPage'));

const App = () => {

    return (
        <Router>
            <DBlockStateProvider>
                <Suspense fallback={<Row justify='center' align='middle' style={{minHeight: '100vh'}}><Spin size='large'/></Row>}>
                    <Routes>
                        <Route element={<AppLayout/>}>
                            <Route path='/' element={<LoginPage/>}/>
                            <Route element={<PublicRoutes/>}>
                                <Route path='/project' element={<ProjectPage/>}/>
                                <Route path='/query/:userPK' element={<QueryPage/>}/>
                                <Route path='/overview/:projectPK' element={<OverviewPage/>}/>
                                <Route path='/subject/:projectPK' element={<SubjectPage/>}/>
                                <Route path='/visit/:projectPK' element={<VisitPage/>}/>
                                <Route path='/dm/project' element={<DMPage/>}/>
                            </Route>
                            <Route element={<PrivateRoutes/>}>
                                <Route path='/admin/project' element={<ProjectSettingPage/>}/>
                                <Route path='/admin/user' element={<UserSettingPage/>}/>
                            </Route>
                        </Route>
                        <Route exact path='/expired' element={<ExpiredPage/>}/>
                        <Route path='*' element={<ExpiredPage/>}/>
                    </Routes>
                </Suspense>
            </DBlockStateProvider>
        </Router>
    )
}

export default App;