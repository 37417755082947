import { useQueryClient } from "@tanstack/react-query";

export const UseMessageApi = () => {
    const queryClient = useQueryClient();

    const setQueryMessageAPI = (messageApi) => {
        queryClient.setQueryData(['messageApi'], messageApi);
    }

    const getSuccessMessage = (msg) => {
       return queryClient.getQueryData(['messageApi']).success(msg);
    }

    const getErrorMessage = (err) => {
        return queryClient.getQueryData(['messageApi']).error(err.response.data.engMessage ?? err.message);
    }

    const getOtherMessage = (type, msg) => {
        return queryClient.getQueryData(['messageApi']).open({
            type: type,
            content: msg
        })
    }


    return { setQueryMessageAPI, getSuccessMessage, getErrorMessage, getOtherMessage }
};
