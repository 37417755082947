import { useEffect } from 'react';

import { message } from 'antd';

import axios from 'axios';
import { UseMessageApi } from "./hooks/UseMessageApi";

const AxiosConfig = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { setQueryMessageAPI } = UseMessageApi();

    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = process.env.REACT_APP_API_URL === undefined ?
        'https://dev-api.dermatrial.com'
        // 'http://192.168.0.111:8080'
        :
        'https://' + process.env.REACT_APP_API_URL + '.dermatrial.com';
    axios.defaults.headers['X-AUTH-TOKEN'] = localStorage.getItem('token');

    useEffect(() => {
        setQueryMessageAPI(messageApi);
        const resInterceptor = response => {
            return response;
        };

        const errInterceptor = async (error) => {
            if(error.response) {
                let responseData = await error.response.data.engMessage;

                if(typeof responseData === 'undefined'){
                    responseData = JSON.parse(await error.response.data.text()).engMessage;
                }

                messageApi.error(responseData ?? error.message);

                return Promise.reject(error);
            }  else if(error.response === undefined) {
                if(error.code === 'ERR_CANCELED') {
                    return error;
                } else {
                    axios.defaults.headers['X-AUTH-TOKEN'] = localStorage.getItem('refreshToken');
                    localStorage.setItem('token', localStorage.getItem('refreshToken'));
                    return Promise.reject(error);
                }
            }
        };

        const interceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);

        return () => {
            axios.interceptors.response.eject(interceptor);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            {children}
        </>
    );
}

export default AxiosConfig;